<template>
  <div>
    <Header></Header>
    <div class="lone">
      <div class="banner">
        <div class="main_left">
          <div class="nav" v-for="(item, index) in navarr[0][num]" :key="index" @click="router(index)">
            <div class="flex_row">
              <div>{{ item }}</div>
              <div>></div>
            </div>
          </div>
        </div>

        <div class="main_right">
          <div class="manager_box">
            <div class="img_header">
              <img :src="encnimg[num].imgurl" alt="" />
            </div>
            <div class="fs30">{{ navarr[0][num][0] }}</div>
            <div>
              <div>
                <img v-show="loadingstatus" style="margin: 0 auto" src="../img/loading_01.gif" alt="" />
              </div>
              <div class="newsBox" v-for="(item, index) in newdata" :key="index" @click="particulars(item.id)"
                v-show="item.englishTitle == '' && num == 1 ? false : true">

                <div class="newBox_left">
                  <img :src="newdata[index].image" alt="" />
                </div>
                <div class="newBox_right">
                  <h3>
                    {{
    num == 0
      ? newdata[index].title
      : newdata[index].englishTitle
}}
                    <div>{{ newdata[index].releaseTime }}</div>
                  </h3>
                  <div class="content">
                    {{
    num == 0
      ? newdata[index].content
      : newdata[index].englishContent
}}
                  </div>
                </div>

              </div>
            </div>
          </div>
          <el-pagination background layout="prev, pager, next" :total="this.total"
            @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/Herder.vue";
import Footer from "../components/Footer.vue";
import { getNewsList } from "../api/new";
import { mapState } from "vuex";
export default {
  components: { Header, Footer },
  computed: {
    ...mapState(["num"]),
  },
  mounted() {
    this.send();
  },
  watch: {
    Current: function () {
      this.send();
    }
  },
  methods: {
    async send() {
      let params = { current: this.Current, size: "10" };
      const { records, total } = await getNewsList(params);
      this.loadingstatus = false;
      this.newdata = records;
      this.total = total;
    },
    particulars(id) {
      this.$router.push({
        name: "newparticulars",
        query: { id },
        path: "/newparticulars",
      });
    },
    handleCurrentChange(val) {
      this.Current = val;
    },
  },
  data() {
    return {
      newdata: [],
      total: 0,
      Current: 0,
      loadingstatus: true,
      navarr: [
        {
          0: ["新闻中心"],

          1: ["News"],
        },
      ],
      encnimg: [
        {
          imgurl: require("../img/headerimg.png"),
        },
        {
          imgurl: require("../img/headerenimg.png"),
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #a08e75;
}

/deep/.el-pagination.is-background .el-pager li:hover {
  color: #a08e75;
}

.content {
  margin-top: 0.3rem;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 0.20rem;
  padding: 0 1rem 0 0;
  box-sizing: border-box;
}

h3 {
  font-size: 0.18rem;
  margin-bottom: 0.1rem;
  display: flex;
  justify-content: space-between;

  div {
    font-size: 0.11rem;
    color: #666;
  }
}

.newBox_left {
  width: 1.5rem;
  height: 1.2rem;
  display: flex;

  img {
    width: 0.9rem;
    height: 0.9rem;
    margin: auto;
  }
}

.newBox_right {
  flex: 1;
  // background-color: pink;
  height: 100%;
  padding: 0.3rem 0.2rem 0.2rem 0;
  box-sizing: border-box;

  h3 {
    font-size: 14px;
    font-weight: 900;
  }
}

.newsBox {
  width: 8rem;
  height: 1.55rem;
  margin: 0 auto;
  background-color: white;
  margin-bottom: 0.3rem;
  display: flex;
  align-items: center;
}

.img_header {
  height: 0.8rem;
  width: 4.26rem;
  margin: 0 auto;

  img {
    width: 100%;
  }
}

.fs30 {
  margin: 0.8rem auto;
  font-size: 0.30rem;
  text-align: center;
}

.lone {
  width: 100%;
  background-color: #f3f3f4;
  overflow: hidden;
}

.flex_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 0.65rem;
  padding-left: 0.5rem;
  padding-right: 0.2rem;
}

.nav {
  width: 2.7rem;
  height: 0.65rem;
  background: white;
  margin-bottom: 0.05rem;
}

.nav:hover {
  background-color: #a08e75;
  color: white;
}

.banner {
  width: 12.6rem;

  margin: 1rem auto;
  display: flex;
}

.main_left {
  width: 3.47rem;
  height: 100%;
  border-right: 0.02rem solid #dad5ce;
}

.main_right {
  flex: 1;
}
</style>